/* eslint-disable */
import request from "utils/request";
import { db } from "utils/firebase";
import { collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";

export const userList = async (payload) => {
  return request(`admin/users`, payload)
};

export const userAndTeamList = async (payload) => {
  return request(`admin/users-and-team`, payload)
};

export const getManualInvoiceUsers = async (payload) => {
  return request(`admin/manual-invoices/users`, payload, { method: "POST" })
};

export const userListDowngrade = async (payload) => {
  return request(`admin/users/downgrade`, payload)
};

export const userListDowngradeEnterprise = async (payload) => {
  return request(`admin/users/downgrade-enterprise`, payload)
};

export const userGet = async (payload) => {
  return request(`admin/user/${payload}`, {}, { method: "GET" })
};

export const userUpgradePremium = async (payload) => {
  //1 year
  return request(`admin/user/upgrade-premium`, payload, { method: "POST" })
};

export const userDowngradeFree = async (payload) => {
  return request(`admin/user/downgrade-free`, payload, { method: "POST" })
};

export const userUpdate = async (payload) => {
  return request(`admin/user/update`, payload, { method: "PUT" })
};

export const userSyncToSendgrid = async (payload) => {
  return request(`admin/user/sync-to-sendgrid`, payload, { method: "POST" })
};


export const downgradeTrialToFree = async (payload) => {
  return request(`admin/migration/downgradeTrialToFree`, payload, { method: "POST" })
};

export const migrationGiveaway = async (payload) => {
  return request(`admin/migration/migrationGiveaway`, payload, { method: "POST" })
};


export const userDelete = async (payload) => {
  return request(`admin/user`, payload, { method: "POST" })
};

export const getAllUsers = async (payload) => {
  try {
    let users = [];

    const queryUsers = query(collection(db, "users"));
    const querySnapshotUsers = await getDocs(queryUsers);

    querySnapshotUsers.forEach((doc) => {
      users.push({
        userId: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt !== undefined ? doc.data().createdAt.toDate() : undefined,
        updatedAt: doc.data().updatedAt !== undefined ? doc.data().updatedAt.toDate() : undefined,
        startTime: doc.data().startTime !== undefined ? doc.data().startTime.toDate() : undefined,
      });
    });

    return {
      message: "Get users dashboard successfully!!!",
      statusCode: 200,
      data: users,
    };
  } catch (error) {
    console.log("error:", error);
    return {
      data: [],
      statusCode: 500,
      errorCode: "INTERNAL_SERVER_ERROR",
      message: "something went wrong"
    };
  }
};

export const postUsagesIntoUsers = async () => {
  console.log("postUsagesIntoUsers")
  try {
    let users = [];

    // // Set isUsages to null
    // const queryUsers = query(collection(db, "users"));
    // const querySnapshotUsers = await getDocs(queryUsers);

    // console.log("querySnapshotUsers.docs.length:", querySnapshotUsers.docs.length)

    // // Get all users
    // querySnapshotUsers.forEach(async (docItem) => {
    //   if (docItem.data().isUsages === undefined) {
    //     try {
    //       const docRef = doc(db, "users", docItem.id);
    //       await updateDoc(docRef, {
    //         isUsages: null
    //       });

    //       console.log({
    //         status: "success",
    //         message: `isUsages is updated successfully.`,
    //         data: {
    //           id: docItem.id,
    //           ...docItem.data()
    //         }
    //       });
    //     } catch (error) {
    //       console.log("error:", error);
    //     }
    //   }

    //   users.push({
    //     id: docItem.id,
    //     ...docItem.data(),
    //     createdAt: docItem.data().createdAt?.toDate(),
    //     startTime: docItem.data().startTime?.toDate(),
    //     endTime: docItem.data().endTime?.toDate(),
    //   });
    // });

    const queryUsers = query(collection(db, "users"), where("isUsages", "==", null));
    const querySnapshotUsers = await getDocs(queryUsers);

    console.log("~~~total_size_usages:", querySnapshotUsers.docs.length);

    querySnapshotUsers.forEach(async (docItem) => {
      users.push({
        id: docItem.id,
        ...docItem.data(),
        createdAt: docItem.data().createdAt?.toDate(),
        startTime: docItem.data().startTime?.toDate(),
        endTime: docItem.data().endTime?.toDate(),
      });
    });

    // Set isUsages to true or false
    for await (const user of users) {
      const { isUsages, ...restUser } = user;

      if (isUsages === null && restUser.email) {
        console.log("*** user:", user);

        const queryUser = query(collection(db, "users"), where("email", "==", restUser.email.toLowerCase()));
        const querySnapshotUser = await getDocs(queryUser);

        console.log("value.size:", querySnapshotUser.docs.length);

        if (querySnapshotUser.docs.length !== 0) {
          try {
            const docRef = doc(db, "users", user.id);
            await updateDoc(docRef, {
              isUsages: true
            });

            console.log({
              status: "success_true",
              message: `isUsages is updated successfully.`,
              data: user
            });
          } catch (error) {
            console.log("error:", error);
          }
        } else {
          try {
            const docRef = doc(db, "users", user.id);
            await updateDoc(docRef, {
              isUsages: false
            });

            console.log({
              status: "success_false",
              message: `isUsages is updated successfully.`,
              data: user
            });
          } catch (error) {
            console.log("error:", error);
          }
        }
      }
    }

    return users;
  }
  catch (err) {
    console.log(err);
    return;
  }
}

export const postEmailSequences = async () => {
  try {
    let users = [];
    // Set isMigrateEmailSequence to null
    const queryUsers = query(collection(db, "users"));
    const querySnapshotUsers = await getDocs(queryUsers);

    console.log("querySnapshotUsers.docs.length:", querySnapshotUsers.docs.length)

    // Get all users
    querySnapshotUsers.forEach(async (docItem) => {
      if (docItem.data().isMigrateEmailSequence === undefined) {
        try {
          const docRef = doc(db, "users", docItem.id);
          await updateDoc(docRef, {
            isMigrateEmailSequence: true,
          });

          console.log({
            status: "success",
            message: `isMigrateEmailSequence is updated successfully.`,
            data: {
              id: docItem.id,
              ...docItem.data(),
            }
          });
        } catch (error) {
          console.log("error:", error);
        }
      }

      users.push({
        id: docItem.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        startTime: doc.data().startTime?.toDate(),
        endTime: doc.data().endTime?.toDate(),
      });
    });

    return users;
  }
  catch (err) {
    console.log(err);
    return;
  }
}