import request from "utils/request";

export const getAdminAggregatedUsagesByOS = async (payload, token) => {
  return request("admin/aggregated-usages/by-os", payload, {
    method: "POST",
    token
  });
};

export const getAdminAggregatedUsagesByAppVersion = async (payload, token) => {
  return request("admin/aggregated-usages/by-version", payload, {
    method: "POST",
    token
  });
};

export const getAdminAggregatedUsagesByAppUsing = async (payload, token) => {
  return request("admin/aggregated-usages/by-using", payload, {
    method: "POST",
    token
  });
};

export const getAdminDailyStats = async (payload, token) => {
  return request("admin/aggregated-usages/daily-stats", payload, {
    method: "POST",
    token
  });
};

export const getAdminAggregatedUsages = async (payload, token) => {
  return request("admin/aggregated-usages/list", payload, {
    method: "POST",
    token
  });
};


// ==== admin user ====

export const getAdminUserSummaryStats = async (payload, token) => {
  return request("admin/user/aggregated-usages/summary-stats", payload, {
    method: "POST",
    token
  });
};

export const getAdminUserDailyStats = async (payload, token) => {
  return request("admin/user/aggregated-usages/daily-stats", payload, {
    method: "POST",
    token
  });
};

export const getAdminUserDailyAppStats = async (payload, token) => {
  return request("admin/user/aggregated-usages/daily-app-stats", payload, {
    method: "POST",
    token
  });
};
