/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, DatePicker, Modal, Row, Col, Input, Card, Space, Tag, Statistic, message, Select, Tooltip } from 'antd';
import { SearchOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import useAuth from 'hooks/useAuth';
import { userList, userDelete, userUpgradePremium, userDowngradeFree } from 'apis/user';

import UserUpdateForm from './forms/user-update.form';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import './styles.less';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import { useUserHistory } from 'hooks/use-user-history';
import UserPaymentChart from './UserPaymentChart';
import UserUsageChart from './UserUsageChart';

const { RangePicker } = DatePicker;

const Users = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  const searchInputEl = useRef(null);
  const [users, setUsers] = useState([]);

  const [userUpdate, setUserUpdate] = useState({});
  const [usersFilter, setUsersFilter] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');

  const [fromDate, setFromDate] = useState(moment().subtract(3, 'months'));
  // const [fromDate, setFromDate] = useState(moment().subtract(7, 'days'));
  const [toDate, setToDate] = useState(moment());

  // pagination
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPage, setTotalPage] = useState(1);
  const [total, setTotal] = useState(1);

  const [usersHistoryFilter, setUsersHistoryFilter] = useState([]);

  const {
    userIdHistory, setUserIdHistory, addUserIdHistory, pageHistory, setPageHistory, incrementPageHistory, limitHistory,
    totalPageHistory, setTotalPageHistory, isPaused, setIsPaused, usersHistory, setUsersHistory,
    initialLoading, setInitialLoading, loading, setLoading, isCompleted, setIsCompleted, onReset
  } = useUserHistory();
  const loadingRef = useRef(false);

  // const [appsumo, setAppSumo] = useState([]);
  const [source, setSource] = useState();
  const [payment, setPayment] = useState();
  const [email, setEmail] = useState("");

  const [isUpdateModal, setIsUpdateModal] = useState(false);

  const errorMessages = [
    '',
    'NO_DEVICE_FOUND_TOOLTIP',
    'CORRUPTED_INSTALLATION',
    '',
    'MIC_ACCESS_DENIED',
  ];

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      return (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInputEl}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </Space>
        </div>
      );
    },
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const [firstValue, ...restValue] = value;

      if (dataIndex === "usernameReferral" && firstValue === "!") {
        return record[dataIndex]
          ? !record[dataIndex].toString().toLowerCase().includes(restValue.join("").toLowerCase())
          : true;
      }

      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '';
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <div>
              {/* {(dataIndex === 'email' && text?.appsumo !== undefined) && (
                <span style={{ fontWeight: 'bold', marginRight: 5, color: 'orange' }}>[AppSumo]</span>
              )} */}
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? (dataIndex === "source" ? `${text[dataIndex].charAt(0).toUpperCase()}${text[dataIndex].slice(1)}`.toString() : text[dataIndex].toString()) : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(undefined);
      setToDate(undefined);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0])
    }

    if (dates && dates[1]) {
      setToDate(dates[1])
    }
  }

  const columns = [
    {
      title: 'Country',
      align: 'center',
      width: '5%',
      ...getColumnSearchProps('countryCode'),
    },
    {
      title: 'Created At',
      align: 'center',
      dataIndex: 'createdAt',
      width: '10%',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD')}</div>
          </div>
        );
      }
    },
    {
      title: 'Email',
      width: '18%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Source',
      width: '10%',
      align: 'center',
      dataIndex: 'source',
      render: (record) => {
        if (record) {
          if (record === 'manual-invoice') {
            return <Tag color="green">Manual Invoice</Tag>;
          } else {
            return <Tag color="green">{record?.toUpperCase()}</Tag>;
          }
        }
      },
      filterMultiple: true,
      filters: [
        {
          text: 'Appsumo',
          value: 'appsumo',
        },
        {
          text: 'Gumroad',
          value: 'gumroad',
        },
        {
          text: 'Enterprise',
          value: 'enterprise',
        },
        {
          text: 'Giveaway',
          value: 'giveaway',
        },
        {
          text: 'Manual invoice',
          value: 'manual-invoice',
        },
      ],
      onFilter: (value, record) => record.source === value,
    },
    {
      title: 'Role',
      align: 'center',
      render: (record) => {
        if (record?.role === 'free') {
          return (
            <div>
              <Tag>{record?.role?.toUpperCase()}</Tag>
              {record?.gumroad?.quantity && (
                <div style={{ fontSize: 10, marginTop: 5, marginLeft: -5 }}>{record?.gumroad?.quantity} SEAT</div>
              )}
              {record?.quantity && (
                <div style={{ fontSize: 10, marginTop: 5, marginLeft: -5 }}>{record?.quantity} SEAT</div>
              )}
              {record?.source === "manual-invoice" && record?.seat && (
                <div style={{ fontSize: 10, marginTop: 5, marginLeft: -5 }}>{record?.seat} SEAT</div>
              )}
              {record?.payments > 0 && (
                <a target='_blank' rel="noopener noreferrer" href={`/v1/users/sales?email=${record?.email}`} style={{ fontSize: 10, marginTop: 5, marginLeft: -5 }}>{record?.payments} Payment</a>
              )}
            </div>
          );
        } else {
          return <div>
            <Tag color="green">{record?.role?.toUpperCase()}</Tag>
            {record?.gumroad?.quantity && (
              <div style={{ fontSize: 10, marginTop: 5, marginLeft: -5 }}>{record?.gumroad?.quantity} SEAT</div>
            )}
            {record?.quantity && (
              <div style={{ fontSize: 10, marginTop: 5, marginLeft: -5 }}>{record?.quantity} SEAT</div>
            )}
            {record?.source === "manual-invoice" && record?.seat && (
              <div style={{ fontSize: 10, marginTop: 5, marginLeft: -5 }}>{record?.seat} SEAT</div>
            )}
            {record?.payments > 0 && (
              <a target='_blank' rel="noopener noreferrer" href={`/v1/users/sales?email=${record?.email}`} style={{ fontSize: 10, marginTop: 5, marginLeft: -5 }}>{record?.payments} Payment</a>
            )}
          </div>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Free',
          value: 'free',
        },
        {
          text: 'Pre',
          value: 'pre',
        },
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: 'EndTime',
      align: 'center',
      dataIndex: 'endTime',
      width: '10%',
      sorter: (a, b) => moment(a.endTime).unix() - moment(b.endTime).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.endTime).format('YYYY-MM-DD')}</div>
          </div>
        );
      }
    },
    // {
    //   title: 'Downloaded',
    //   dataIndex: 'isDownloaded',
    //   render: (record) => {
    //     if (record === true) {
    //       return <Tag color="green">Yes</Tag>;
    //     }
    //   },
    //   width: '10%',
    //   align: 'center',
    //   filterMultiple: true,
    //   filters: [
    //     {
    //       text: 'Downloaded',
    //       value: true,
    //     },
    //   ],
    //   onFilter: (value, record) => record.isDownloaded === value,
    // },
    // {
    //   title: 'Installed',
    //   dataIndex: 'isInstalled',
    //   render: (record) => {
    //     if (record === true) {
    //       return <Tag color="green">Yes</Tag>;
    //     }
    //   },
    //   width: '10%',
    //   align: 'center',
    //   filterMultiple: true,
    //   filters: [
    //     {
    //       text: 'Installed',
    //       value: true,
    //     },
    //   ],
    //   onFilter: (value, record) => record.isInstalled === value,
    // },
    {
      title: 'Installed',
      //dataIndex: 'isInstalledSuccessfully',
      render: (record) => {
        if (record?.isInstalledSuccessfully === true) {
          return <Tag color="green">Success</Tag>;
        }
        if (record?.isInstalledSuccessfully === false) {
          return <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
            <Tag color="red">Error</Tag>
            <div style={{ textAlign: 'left' }}>Mic: <span>{errorMessages[parseInt(record?.micError)]}</span></div>
            <div style={{ textAlign: 'left', marginTop: 5 }}>Speaker: <span>{errorMessages[parseInt(record?.speakerError)]}</span></div>
            <div style={{ textAlign: 'left', marginTop: 5 }}>
              <Tooltip title={record?.appSettings}>
                <span>AppSettings</span>
              </Tooltip>
            </div>
          </div>;
        }
      },
      width: '10%',
      align: 'center',
      filterMultiple: true,
      filters: [
        {
          text: 'Success',
          value: true,
        },
        {
          text: 'Error',
          value: false,
        },
        {
          text: 'Undefined',
          value: undefined,
        },
      ],
      onFilter: (value, record) => record.isInstalledSuccessfully === value,
    },
    {
      title: 'Usages',
      align: 'center',
      dataIndex: 'isUsages',
      render: (record) => {
        if (record === true) {
          return <Tag color="green">Yes</Tag>;
        }
        return;
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Used',
          value: true,
        },
        {
          text: 'Unused',
          value: false,
        },
      ],
      onFilter: (value, record) => {
        return record.isUsages === value;
      },
    },
    {
      title: 'Expired',
      align: 'center',
      dataIndex: 'isAfter',
      render: (record) => {
        if (record === false) {
          return <Tag color="red">Expired</Tag>;
        }
      },
      width: '8%',
      filterMultiple: true,
      filters: [
        {
          text: 'Expired',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isAfter === value,
    },
    {
      title: 'Recorded',
      align: 'center',
      dataIndex: 'recordingSize',
      width: '4%',
      render: (record) => {
        return (
          <div>
            {record > 0 ? record : ""}
          </div>
        );
      },
      filters: [
        {
          text: 'Recorded',
          value: true,
        },
        {
          text: 'Unrecorded',
          value: false,
        }
      ],
      onFilter: (value, record) => {
        if (value) {
          return record.recordingSize > 0;
        } else {
          return record.recordingSize <= 0;
        }
      },
    },
    {
      width: '8%',
      key: "action",
      render: (record) =>
        <div>
          {(user?.role === "super") && (
            <>
              <Button
                type='link'
                disabled={record?.role === 'pre'}
                ghost
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Are you sure to upgrade premium (source = Giveaway)',
                    okText: 'Ok',
                    cancelText: 'Không',
                    onOk: () => {
                      let userId = record?.userId;
                      let payload = {
                        userId,
                      }
                      userUpgradePremium(payload)
                        .then(async (result) => {
                          setInitialLoading(true);
                          message.success('Upgrade premium is successful');

                          const usersFilterUpdate = usersHistory.map(item => {
                            if (item.userId === record?.userId) {
                              return {
                                ...item,
                                role: 'pre',
                                source: 'giveaway',
                                endTime: moment().clone().add(1, 'month'),
                              }
                            } else {
                              return item;
                            }
                          })
                          setUsersHistory(usersFilterUpdate);
                          setInitialLoading(false);
                        })
                        .catch(err => console.log(err))
                    }
                  });
                }}
              >Upgrade</Button>
              <Button
                type='link'
                disabled={record?.role === 'free'}
                ghost
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Are you sure to downgrade free?',
                    okText: 'Ok',
                    cancelText: 'Không',
                    onOk: () => {
                      let userId = record?.userId;
                      let payload = {
                        userId,
                      }
                      userDowngradeFree(payload)
                        .then(async () => {
                          message.success('Downgrade free is successful')
                          setInitialLoading(true);
                          const usersFilterUpdate = usersHistory.map(item => {
                            if (item.userId === record?.userId) {
                              return {
                                ...item,
                                role: 'free',
                              };
                            } else {
                              return item;
                            }
                          });
                          setUsersHistory(usersFilterUpdate);
                          setInitialLoading(false);
                        })
                        .catch(err => console.log(err))
                    }
                  });
                }}
              >
                Downgrade
              </Button>
            </>
          )
          }
        </div>
    },
    {
      width: '8%',
      key: "action",
      render: (record) =>
        <div>
          {(user?.role === "super") && (
            <div>
              <Button
                type='link'
                onClick={() => {
                  navigate(`/v1/users/usage-detail/${record.userId}`)
                }}
              >
                View Usage
              </Button>
              <Button
                type='link'
                disabled={record?.role === 'free'}
                onClick={() => {
                  const user = usersHistoryFilter.find(
                    (item) => item.userId === record.userId
                  );
                  setUserUpdate(user);
                  setIsUpdateModal(true);
                }}
              >
                Edit
              </Button>
              <Button
                style={{ color: 'red' }}
                type='link'
                ghost
                onClick={() => {
                  Modal.confirm({
                    title: 'Confirm',
                    content: 'Are you sure to delete this user?',
                    okText: 'Ok',
                    cancelText: 'Không',
                    onOk: () => {
                      let { userId, email } = record;
                      let payload = {
                        userId,
                        email,
                      }
                      userDelete(payload)
                        .then(async () => {
                          setInitialLoading(true);

                          const usersFilterUpdate = usersHistory.filter(item => {
                            return item.userId !== userId;
                          });

                          message.success('This user has been deleted successfully');

                          setUsersHistory(usersFilterUpdate);
                          const newTotal = usersFilterUpdate.length;
                          const newTotalPage = Math.ceil(newTotal / limit);

                          setTotal(usersFilterUpdate);
                          setTotalPage(newTotalPage);
                          setUsersHistory(usersFilterUpdate);
                          setInitialLoading(false);
                        })
                        .catch(err => console.log(err))
                    }
                  });
                }}
              >Delete</Button>
            </div>
          )
          }
        </div>
    },
  ];

  const fetchUsersV1 = async (currentPage = 1, currentSize = 20) => {
    try {
      setLoading(true);

      const finalPage = currentPage ? currentPage : page;
      const lastUserId = finalPage > 1 ? userIdHistory[finalPage - 2] : null;

      const payload = {
        fromDate: fromDate.format('YYYY-MM-DD'),
        toDate: toDate.format('YYYY-MM-DD'),
        page: finalPage,
        limit: currentSize ? currentSize : limit,
        lastVisibleId: lastUserId
      };
      const res = await userList(payload);

      setUsers(res.result.users)
      setPage(res.result.page);
      setLimit(res.result.limit);
      setTotalPage(res.result.totalPage);
      setTotal(res.result.total);

      if (currentPage > userIdHistory.length) {
        const lastUser = res.result.users[res.result.users.length - 1];
        if (lastUser) {
          setUserIdHistory((prev) => [...prev, lastUser.userId]);
        }
      }

    } catch (error) {
      console.log("error when fetching page:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsersV2 = async (currentPage = 1, currentSize = 1000, lastUserId = null) => {
    let userLength = 0;
    let totalPage = 0;

    try {
      const payload = {
        fromDate: fromDate.format('YYYY-MM-DD'),
        toDate: toDate.format('YYYY-MM-DD'),
        page: currentPage,
        limit: currentSize ? currentSize : limitHistory,
        lastVisibleId: lastUserId
      };


      if (email) {
        payload.email = email;
      }

      const res = await userList(payload);

      const newUserHistory = [
        ...usersHistory,
        ...res.result.users
      ];

      setUsersHistory(newUserHistory);
      setUsersHistoryFilter(newUserHistory);
      setTotalPageHistory(res.result.totalPage);
      setTotal(res.result.total);

      if (res.result.users.length > 0) {
        const lastUser = res.result.users[res.result.users.length - 1];

        if (lastUser) {
          addUserIdHistory(lastUser.userId);
        }

        if (currentPage === 1) {
          const newTotalPage = Math.ceil(res.result.total / limit);
          setTotalPage(newTotalPage);
          setUsers(res.result.users.slice(0, limit));
        }

        if (currentPage === res.result.totalPage) {
          setIsPaused(false);
          setIsCompleted(true);
        }
      }

      userLength = res.result.users.length;
      totalPage = res.result.totalPage;
    } catch (error) {
      console.log("error when fetching page:", error);
    } finally {
      return {
        userLength,
        totalPage
      };
    }
  };

  const loadPage = async () => {
    try {
      loadingRef.current = true;
      setLoading(true);

      const lastUserId = pageHistory > 1 ? userIdHistory[pageHistory - 2] : null;  // Lấy lastUserId từ history
      const usersFetched = await fetchUsersV2(pageHistory, limitHistory, lastUserId);

      // Nếu có thêm dữ liệu, tiếp tục tăng pageHistory
      if (usersFetched.userLength > 0) {
        if (pageHistory === 1) {
          if (usersFetched.totalPage > 1) {
            incrementPageHistory();
          }
        } else {
          if (pageHistory < totalPageHistory) {
            incrementPageHistory();
          }
        }
      }

      if (pageHistory === 1) {
        setInitialLoading(false); // Tắt loading lần đầu sau khi trang đầu tiên tải xong
      }
    } catch (error) {
      console.log("error in loadPage:", error);
    } finally {
      loadingRef.current = false;
      setLoading(false);
    }
  };

  const onResetState = async () => {
    onReset();
  };

  useEffect(() => {
    if (user && pageHistory <= totalPageHistory && !isPaused && !loading && !isCompleted) {
      loadPage();
    }
  }, [user, pageHistory, totalPageHistory, isPaused, isCompleted]);

  const handleChangePage = async (page, limit) => {
    setPage(page);
    setLimit(limit);

    const startIndex = (page - 1) * limit;
    const endIndex = page * limit;

    const currentPageUsers = usersHistory.slice(startIndex, endIndex); // Lấy dữ liệu cho trang hiện tại

    // Cập nhật lại state để hiển thị dữ liệu người dùng tương ứng với trang hiện tại
    setUsers(currentPageUsers);

    // await fetchUsers(page, limit);
  };

  // Hàm xử lý button dừng/tiếp tục
  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  useEffect(() => {
    let filteredUsers = [...usersHistory]; // Clone users list

    // Lọc theo source
    if (source !== undefined && source !== "all") {
      if (source === "appsumo") {
        filteredUsers = filteredUsers.filter(user => user.source === source);
      }
    }

    // Lọc theo payment
    if (payment !== undefined && payment !== "all") {
      if (payment === "paid") {
        filteredUsers = filteredUsers.filter(user => user.role === "pre" && user.payments > 0);
      } else if (payment === "unpaid") {
        filteredUsers = filteredUsers.filter(user => !(user.role === "pre" && user.payments > 0));
      }
    }

    setUsersHistoryFilter(filteredUsers);
  }, [usersHistory, source, payment]);

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Users</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RangePicker
                ranges={{
                  'Today': [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
                  'This Year': [moment().startOf('year'), moment().endOf('year')],
                }}
                onChange={onChange}
                defaultValue={[moment().subtract(3, 'months'), moment()]}
                allowClear={false}
              />

              <Select
                placeholder="Source"
                style={{ width: 100, marginLeft: 16 }}
                value={source}
                onChange={(value) => {
                  setSource(value);
                }}
              >
                <Select.Option value="appsumo">Appsumo</Select.Option>
                <Select.Option value="giveaway">Giveaway</Select.Option>
                <Select.Option value="others">Others</Select.Option>
                <Select.Option value="all">All</Select.Option>
              </Select>

              <Select
                placeholder="Payment"
                style={{ width: 100, marginLeft: 16 }}
                value={payment}
                onChange={(value) => {
                  setPayment(value);
                }}
              >
                <Select.Option value="paid">Paid</Select.Option>
                <Select.Option value="unpaid">Unpaid</Select.Option>
                <Select.Option value="all">All (Paid & Unpaid)</Select.Option>
              </Select>

              <Input
                placeholder="Email"
                style={{ width: 120, marginLeft: 16 }}
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />

              <Button
                type="primary"
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={!fromDate || !toDate || !(user && (pageHistory <= totalPageHistory || totalPageHistory === 0) && !isPaused && !loading)}
                onClick={async () => {
                  await onResetState();
                }}
              >
                Search
              </Button>

              {!initialLoading && usersHistory.length < total && (
                <Row
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "16px",
                    margin: "12px 24px"
                  }}
                >
                  <Button onClick={togglePause}>
                    {isPaused ? "Resume" : "Pause"}
                  </Button>

                  <span>Loaded <span style={{ fontWeight: 600 }}>{usersHistory.length} / {total}</span> users</span>
                </Row>
              )}


              {/* <Button
                style={{ float: 'right' }}
                type={'link'}
                icon={<DownloadOutlined />}
                onClick={() => {
                  const totalUsers = appsumo.reduce((prev, item) => {
                    return prev + item.users;
                  }, 0);
                  const templateData = totalUsers > 0 ? [...appsumo, {
                    countryCode: "Total",
                    users: totalUsers,
                  }] : [...appsumo];

                  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                  const fileExtension = '.xlsx';
                  const ws = XLSX.utils.json_to_sheet(templateData);
                  const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                  const data = new Blob([excelBuffer], { type: fileType });
                  const fileName = `users_appsumo_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                  FileSaver.saveAs(data, fileName + fileExtension);
                }}
              >
                Download2
              </Button> */}
              <Button
                style={{ float: 'right' }}
                type={'link'}
                icon={<DownloadOutlined />}
                onClick={() => {
                  const templateData = usersHistoryFilter.map(item => {
                    return {
                      'Created At': item?.createdAt,
                      'ID': item?.userId,
                      'Email': item?.email,
                      'Role': item?.role?.toUpperCase(),
                      'VIP': item?.isVip ? 'X' : ''
                    };
                  });

                  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                  const fileExtension = '.xlsx';
                  const ws = XLSX.utils.json_to_sheet(templateData);
                  const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                  const data = new Blob([excelBuffer], { type: fileType });
                  const fileName = `users_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                  FileSaver.saveAs(data, fileName + fileExtension);
                }}
              >
                Download
              </Button>
              {/* <Button
                  style={{ float: 'right' }}
                  type={'link'}
                  icon={<RetweetOutlined />}
                  onClick={async () => {
                    await downgradeTrialToFree({})
                    alert('downgradeTrialToFree is successful')
                  }}
                >Downgrade Trial To Free</Button> */}
              {/* <Button
                style={{ float: 'right' }}
                type={'link'}
                icon={<CaretRightOutlined />}
                onClick={() => {
                  runScheduleDowngradeFreeWhenExpired()
                    .then(result => {
                      message.success('Done')
                      console.log('result: ', result)
                    })
                    .catch(err => {
                      console.log('err: ', err)
                      message.error('Error')
                    })
                }}
              >
                Downgrade Schedule (Manually)
              </Button> */}
              {/* <Button
                  style={{ float: 'right' }}
                  type={'link'}
                  icon={<RetweetOutlined />}
                  onClick={async () => {
                    await Promise.all(usersFilter.map(async item => {
                      let usage = await getUsages({
                        email: item?.email
                      });

                      // //update firebase
                      // let user = {
                      //   userId: item?.userId,
                      //   isUsage: usage.data.length > 0 ? true : false,
                      // };
                      // userUpdate(user);

                      //update sendgrid
                      let {role, email, createdAt, startTime, endTime, isGiveAway, countryCode} = item;
                      let userSendgrid = {
                        role, 
                        email, 
                        createdAt: createdAt ? moment(createdAt).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'), 
                        startTime: startTime ? moment(startTime).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'),
                        endTime: endTime ? moment(endTime).format('MM/DD/YYYY') : moment().format('MM/DD/YYYY'), 
                        isGiveAway, 
                        countryCode,
                        isUsage: usage.data.length > 0 ? 1 : 0,
                      };
                      userSyncToSendgrid(userSendgrid)
                    }))
                    alert('Sync to Sendgrid is successful')
                  }}
                >Sync to Sendgrid</Button> */}
            </Col>
          </Row>
        </Card>

        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{ marginBottom: 24 }}>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Total"
                  loading={initialLoading}
                  value={usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length}
                  valueStyle={{
                    color: '#4a90e2',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Paid"
                  loading={initialLoading}
                  value={usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.role === 'pre' && x.payments > 0;
                  })?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Unpaid"
                  loading={initialLoading}
                  value={usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && !(x.role === 'pre' && x.payments > 0);
                  })?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Percentage of paid users / total users"
                  loading={initialLoading}
                  value={usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length ? _.round((usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.role === 'pre' && x.payments > 0;
                  })?.length) * 100 / (usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length), 2) : 0}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                  suffix="%"
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <UserPaymentChart
              usersHistoryFilter={usersHistory}
              fromDate={fromDate}
              toDate={toDate}
            />
          </Row>
        </Card>

        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between" style={{ marginBottom: 24 }}>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Total"
                  loading={initialLoading}
                  value={usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length}
                  valueStyle={{
                    color: '#4a90e2',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Used"
                  loading={initialLoading}
                  value={usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.isUsages === true;
                  })?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Unused"
                  loading={initialLoading}
                  value={usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.isUsages !== true;
                  })?.length}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                />
              </Card>
            </Col>
            <Col span={6}>
              <Card>
                <Statistic
                  title="Percentage of used users / total users"
                  loading={initialLoading}
                  value={usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length ? _.round((usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid && x.isUsages === true;
                  })?.length) * 100 / (usersHistoryFilter.filter(x => {
                    let isInvalid = false;

                    if (fromDate && toDate) {
                      isInvalid = moment(x.createdAt).isBefore(fromDate.clone().startOf("day")) || moment(x.createdAt).isAfter(toDate.clone().endOf("day"));
                    }

                    return !isInvalid;
                  })?.length), 2) : 0}
                  valueStyle={{
                    color: '#333',
                    fontWeight: 'bold'
                  }}
                  suffix="%"
                />
              </Card>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <UserUsageChart
              usersHistoryFilter={usersHistory}
              fromDate={fromDate}
              toDate={toDate}
            />
          </Row>
        </Card>

        <Table
          loading={initialLoading}
          bordered
          columns={columns.filter(column => {
            if (column.key === "action") {
              if (user?.role === "super" || user?.role === "sale") {
                return true;
              }
              return false;
            }
            return true;
          })}
          dataSource={usersHistoryFilter}
          pagination={{
            defaultPageSize: 20
          }}
          // dataSource={usersFilter}
          // pagination={{
          //   pageSize: Number(limit),
          //   current: Number(page),
          //   total: total,
          //   onChange: handleChangePage,
          //   size: 'small',
          //   showLessItems: true,
          //   showSizeChanger: false,
          //   simple: true,
          //   className: "pagination-container"
          // }}
          rowKey={(row) => row.userId}
        />

        <Modal
          open={isUpdateModal}
          title={`User / Edit`}
          onCancel={() => setIsUpdateModal(false)}
          destroyOnClose={true}
          width={980}
          centered
          footer={null}
        >
          <UserUpdateForm
            user={userUpdate}
            onCancel={() => setIsUpdateModal(false)}
            onCallback={async (endTime, source, seat) => {
              setInitialLoading(true);

              const usersFilterUpdate = usersHistory.map(item => {
                if (item.userId === userUpdate.userId) {
                  return {
                    ...item,
                    endTime: moment(endTime),
                    source: source,
                    isAfter: moment(endTime).isAfter(new moment()),
                    seat
                  }
                } else {
                  return item;
                }
              });
              setUsersHistory(usersFilterUpdate)
              setInitialLoading(false)
              setIsUpdateModal(false)
            }}
          />
        </Modal>
      </Layout>
    </Layout>
  );
};

export default Users;